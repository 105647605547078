@import "assets/scss/variables.scss";

.client-drawer {
  width: 260px;
  height: 100%;
  box-sizing: border-box;
  background-color: $grey-500;
  padding: 16px;
  position: fixed;
  z-index: 10;
  transition: all 0.4s ease;

  .drawer-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 80px);
  }

  .intuilize-logo {
    margin-bottom: 32px;
  }

  .ctn-logo {
    display: flex;
  }

  .ctn-logo-collapsed {
    justify-content: center;
  }

  .add-new-dashboard-btn {
    border: 1px dashed $grey-300;
    color: $grey-100;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
  }

  .client-navigation {
    margin-top: 8px;
  }

  .drawer-tab {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    user-select: none;

    .tab-content {
      color: white;
      font-family: "Rubik-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-active {
      background-color: $secondary-500;
      font-weight: 600;
      transform: scale(1);
      .tab-content {
        color: $grey-500;
        font-weight: 600;
        font-family: "Rubik-Semibold";
      }
    }
  }

  .profile-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
  }

  .hidden-title {
    padding: 24px 16px 8px;
    font-family: "Rubik-Semibold";
    color: $secondary-500;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }
}

.ctn-collapse-button {
  justify-content: end;
}
.btn-collapse {
  border: 1px solid white !important;
  background-color: transparent;
  color: white !important;
  padding: 0px 5px;
  border-radius: 3px;
  cursor: pointer;
}
.collapse-sidebar {
  width: 80px;
  transition: all 0.4s ease;
}

.floating-collapse-button {
  position: fixed;
  bottom: 110px;
  left: 240px;
  width: 40px;
  height: 40px;
  background-color: $secondary-500; // Adjust color as needed
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: lighten($secondary-500, 10%);
  }

  .collapse-icon {
    color: $grey-500; // Adjust color as needed
    font-size: 18px;
    transition: transform 0.3s ease;
  }
}

.floating-collapse-buttonCollapsed {
  position: fixed;
  bottom: 110px;
  left: 60px;
  width: 40px;
  height: 40px;
  background-color: $secondary-500; // Adjust color as needed
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: lighten($secondary-500, 10%);
  }

  .collapse-icon {
    color: $grey-500; // Adjust color as needed
    font-size: 18px;
    transition: transform 0.3s ease;
  }
}
.menu-item-headerCollapsed {
  width: 45px;
  margin-left: 13px;
  transition: all 0.4s ease;
}
